var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entity)?_c('layout-sidenav',{staticClass:"entity-details",attrs:{"sidenav-force-icon-slot":true,"sidenav-list":[
		{ to: ("/entities/" + _vm.id + "/general"), label: _vm.$t("entityDetails.navigation.General") },
		{ to: ("/entities/" + _vm.id + "/settings"), label: _vm.$t("entityDetails.navigation.Settings"), icon: 'settings' },
		{ to: ("/entities/" + _vm.id + "/members"), label: _vm.$t("entityDetails.navigation.Members"), icon: 'group' },
		'divider',
		{ to: ("/entities/" + _vm.id + "/notes"), label: _vm.$t("entityDetails.navigation.Notes"), icon: 'note' },
		{ to: ("/entities/" + _vm.id + "/templates"), label: _vm.$t("entityDetails.navigation.Templates"), icon: 'collections' },
		{ can: 'admin', type: 'divider' },
		{ can: 'admin', to: ("/entities/" + _vm.id + "/ads/small"), label: _vm.$t("Ads: Small"), icon: 'crop_7_5' },
		{ can: 'admin', to: ("/entities/" + _vm.id + "/ads/large"), label: _vm.$t("Ads: Large"), icon: 'crop_square' } ]}},[_c('template',{slot:"breadcrumbs"},[_c('entity-breadcrumbs',{attrs:{"entity":_vm.entity}})],1),_c('template',{slot:"sidenav-title"},[_c('v-icon',[_vm._v(_vm._s(_vm.$scrinz.getIconForEntityType(_vm.entity.type)))]),_vm._v(" "+_vm._s(_vm.entity.name)+" ")],1),_c('template',{slot:"content"},[_c('router-view')],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }