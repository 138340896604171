
































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import natsort from "javascript-natural-sort";

import { EntityDto } from "@scrinz/dtos";
import { AutoApproveNumber } from "@/store";

@Component
export default class AutoApporveNumbersList extends Vue {
	@Prop({ required: true, type: [EntityDto, Object] })
	entity!: EntityDto;

	@Prop({ required: true, type: [Function] })
	editFunction!: any;

	get headers() {
		return [
			{ text: this.$t("Phone number"), value: "number", align: "right", sortable: false, width: "160px" },
			{ text: this.$t("Description"), value: "description", align: "left", sortable: false },
			{ text: this.$t("Expiration Date"), value: "expirationDate", align: "left", sortable: false },
			{ value: "actions", sortable: false },
		];
	}

	get numbers(): AutoApproveNumber[] {
		return this.$store.getters.autoApproveNumbersFor(this.entity);
	}

	get sorted() {
		return this.numbers.sort((a, b) => natsort(a.description, b.description));
	}

	isExpired(number: AutoApproveNumber) {
		const now = new Date();

		if (!number.expirationDate) return false;
		if (!(number.expirationDate instanceof Date)) {
			number.expirationDate = new Date(number.expirationDate);
		}

		return number.expirationDate.valueOf() < now.valueOf();
	}

	async remove(num: AutoApproveNumber) {
		const confirmed = await this.$confirm(
			this.$t(`confirmDelete.text`, num),
			{
				color: "error",
				title: this.$t(`confirmDelete.title`),
			},
		);

		if (num.id && confirmed) {
			await this.$store.dispatch("deleteAutoApproveNumber", num);
		}
	}
}
