import { Ability } from "@casl/ability";
import * as Cookie from "es-cookie";
import * as jwt from "jsonwebtoken";
import { ActionTree } from "vuex";

import { ChangePasswordResponseDto } from "@scrinz/dtos";

import { defineAbilitiesFor } from "@/plugins/casl";
import http from "@/http";
import router from "@/router";
import { RootState } from "@/store";
import { routeRequiresAuth } from "@/helpers";
import { ChangePasswordRequestInterface, SessionState } from "./types";

// The key of the cookie used to store the token.
const TOKEN_COOKIE = "token";

export const actions: ActionTree<SessionState, RootState> = {
	// Initiates the Session store module, loading token from cookie and
	// dispatching to `setToken`.
	async init({ dispatch, getters }) {
		const cookieToken = Cookie.get(TOKEN_COOKIE);

		if (cookieToken) await dispatch("setToken", cookieToken);

		return { session: { isLoggedIn: getters.isLoggedIn } };
	},

	async login({ dispatch }, { email, password }) {
		try {
			const res = await http.post(`authentication/login`, {
				email,
				password,
			});

			await dispatch("setToken", res.data.token);

			return true;
		} catch (err) {
			await dispatch("setToken", null);

			if (err.response && err.response.status === 401) {
				return false;
			}

			throw new Error("Error occured trying to log in.");
		}
	},

	// Dispatches an action to `setToken` with `null` value, effectively removing
	// the session. Also routes the user to "/logout" if current route requires
	// authorization.
	async logout({ dispatch, commit }, redirect = true) {
		await dispatch("setToken", null);
		commit("RESET_STATE");

		if (redirect && routeRequiresAuth(router.currentRoute)) {
			router.push({ name: "auth--logout" });
		}
	},

	async resetPassword({ dispatch }, payload) {
		await dispatch("logout", false);

		const res = await http.post(`authentication/reset-password`, { ...payload });

		if (!res || res.status !== 200) {
			throw new Error("Failed to request password reset");
		}

		return true;
	},

	async resetPasswordConfirm({ dispatch }, payload) {
		await dispatch("logout", false);

		const res = await http.post(`authentication/reset-password/confirm`, { ...payload });

		if (!res || res.status !== 200) {
			throw new Error("Failed to confirm password reset");
		}

		return true;
	},

	async changePassword({}, payload: ChangePasswordRequestInterface /* ChangePasswordRequestDto */) {
		const res = await http.post<ChangePasswordResponseDto>("account/change-password", payload);

		if (!res || res.status !== 200) {
			throw new Error("Failed to change password");
		}

		return res.data.passwordChanged;
	},

	// Sets the session token to given value `token`. Ensures that cookie is
	// synchronized and valid, as well as dispatching further state depending on
	// validity.
	async setToken({ commit, dispatch, getters }, token: string | null) {
		let userId: number | null = null;
		let admin = false;

		// If token was provided, try decoding.
		if (token) {
			const decoded = jwt.decode(token);

			// Ensure decoded successfully and has a user property of value number.
			if (
				decoded
				&& typeof decoded === "object"
				&& decoded.user
				&& typeof decoded.user === "number"
			) {
				// Set userId to decoded user.
				userId = decoded.user;

				if (decoded.admin) {
					admin = true;
				}

				// Set a cookie with provided token, to remember between reloads.
				Cookie.set(TOKEN_COOKIE, token);
			}
		}

		// If not token, or userId still is undefined.
		if (!token || !userId) {
			// Set token to an explicit null.
			token = null;
			userId = null;

			// Remove cookie, as invalid.
			Cookie.remove(TOKEN_COOKIE);
		}

		// Set or delete the `Authorization` header from all http requests,
		// depending on whether token is valid.
		if (token) {
			http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		} else {
			delete http.defaults.headers.common.Authorization;
		}

		if (userId) {
			await dispatch("fetchUsers");
			// const res = await http.get(`users/${userId}`);

			// if (!res || res.status !== 200) {
			// 	return dispatch("setToken", null);
			// }
		}

		// Commit token user id.
		commit("SET_TOKEN", token);
		commit("SET_USER_ID", userId);
		commit("SET_ABILITY", userId ? defineAbilitiesFor({
			admin,
			id: userId,
		}) : new Ability([]));

		// Dispatch that session has changed.
		await dispatch("onSessionChange", getters.hasSession);
	},

	// Called from `setToken` with the updated session state.
	onSessionChange({}, _state: boolean) {
		// To be consumed by other modules.
	},
};
