






























import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { EntityDto } from "@scrinz/dtos";
import LayoutFluidCard from "@/layouts/FluidCard.vue";
import { EntityList } from "@/components/Entity";

@Component({
	title: "Entities",
	components: { EntityList, LayoutFluidCard },
})
export default class ClientEntities extends Vue {
	@Getter("entityContextId")
	entityId!: number;

	@Getter entities!: EntityDto[];

	sortBy: string = "name";
	sortDirection: string = "ASC";

	toggleSort(by: string) {
		if (by == this.sortBy) {
			if (this.sortDirection == "ASC") {
				this.sortDirection = "DESC";
			} else {
				this.sortDirection = "ASC";
			}
		}

		if (by != this.sortBy) {
			this.sortDirection = "ASC";
			this.sortBy = by;
		}
	}
}
