














import { Component, Prop, Vue } from "vue-property-decorator";

import { TransitStop } from "@/store";

@Component
export default class TransitTimesStopSelectorSelectedStopsList extends Vue {
	@Prop({ default: [] })
	stops!: TransitStop[];

	async removeStop(stop: TransitStop) {
		return this.$store.dispatch("deleteTransitStop", stop);
	}
}
