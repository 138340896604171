import * as Cookie from "es-cookie";
import { Action, ActionTree } from "vuex";

import { EntityDto, EntityUserDto } from "@scrinz/dtos";
import http from "@/http";
import { RootState } from "@/store";
import { EntitiesState } from "./types";

const ENTITY_ID_COOKIE = "entityId";

const onSessionOrConnectivityChange: Action<EntitiesState, RootState> = async ({ dispatch, getters }) => {
	if (getters.hasSession && getters.hasConnectivity) {
		await dispatch("fetchEntities");
	}
};

export const actions: ActionTree<EntitiesState, RootState> = {
	async init({ dispatch }) {
		await dispatch("setEntityContext", Cookie.get(ENTITY_ID_COOKIE));
	},

	onSessionChange: onSessionOrConnectivityChange,
	onConnectivityChange: onSessionOrConnectivityChange,

	async fetchEntities({ commit, dispatch, getters, state }) {
		const res = await http.get("/entities");

		if (res && res.status === 200) {
			commit("UPDATE_ENTITIES", res.data);
		} else {
			throw new Error(`Failed to fetch entities.`);
		}

		const entityContext = getters.entityContextId;

		if (!entityContext || !getters.getEntity(entityContext)) {
			await dispatch("setEntityContext", state.entities[0].id);
		}
	},

	async createEntity({ commit }, entity: EntityDto) {
		const res = await http.post("/entities", entity);

		if (res && res.status === 201) {
			// Add newly created flag.
			res.data.newlyCreated = true;

			commit("UPDATE_ENTITY", res.data);
		} else {
			throw new Error(`Failed to create entity.`);
		}
	},

	async updateEntity({ commit, getters }, entity: EntityDto) {
		const res = await http.put(`/entities/${entity.id}`, entity);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to update entity with id "${entity.id}".`);
		}

		// Add newly updated flag.
		res.data.newlyUpdated = true;

		commit("UPDATE_ENTITY", res.data);

		return getters.getEntity(entity.id);
	},

	async deleteEntity({ commit, getters }, payload: { entity: EntityDto; withChildren?: "delete"|"move"; moveTo?: EntityDto }) {
		const { entity, withChildren, moveTo } = payload;
		const res = await http.delete(`/entities/${entity.id}`, {
			data: {
				withChildren,
				moveTo,
			},
		});

		commit("UPDATE_ENTITIES", res.data);

		return getters.getEntity(entity.id);
	},

	async regenerateEntityApiKey({ commit }, entity: EntityDto) {
		const res = await http.post(`/entities/${entity.id}/regenerate-api-key`);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to regenerate API key for entity with id "${entity.id}".`);
		}

		commit("UPDATE_ENTITY", res.data);
	},

	async addMembersToEntity({ commit }, payload: {
		entity: EntityDto;
		members: any[];
	}) {
		const { entity, members } = payload;
		const res = await http.post(`/entities/${entity.id}/members`, members);

		if (!res || res.status !== 201) {
			throw new Error(`Failed to add members.`);
		}

		commit("UPDATE_USERS", res.data);
	},

	async removeMemberFromEntity({ commit }, payload: {
		entity: EntityDto;
		member: any;
	}) {
		const { entity, member } = payload;
		const res = await http.delete(`/entities/${entity.id}/members/${member.id}`);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to remove member.`);
		}

		commit("REMOVE_USER_PERM", {
			entity,
			user: member,
		});
	},

	async updateMemberPermissions({ commit }, { entity, member, permissions }: {
		entity: EntityDto;
		member: any;
		permissions: any;
	}) {
		const res = await http.put(`/entities/${entity.id}/members/${member.id}/permissions`, permissions);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to update member permissions.`);
		}

		commit("UPDATE_USER_PERMS", {
			user: member,
			permissions: res.data,
		});
	},

	async fetchMembersOfEntity({}, entity: EntityDto): Promise<EntityUserDto[]> {
		const res = await http.get(`/entities/${entity.id}/members`);

		if (!res || res.status !== 200) {
			throw new Error("Couldn't get members of entity.");
		}

		return res.data;
	},

	async fetchInheritedMembersOfEntity({ dispatch, getters }, entity: EntityDto): Promise<EntityUserDto[]> {
		if (!entity.parentId) return [];

		const parent = await getters.getEntity(entity.parentId);
		const members: EntityUserDto[] = await dispatch("fetchMembersOfEntity", parent);

		if (parent.parentId) {
			const parentMembers = await dispatch("fetchInheritedMembersOfEntity", parent);

			if (parentMembers && parentMembers instanceof Array) {
				parentMembers.forEach(pm => {
					if (!members.find(m => m.userId === pm.userId)) {
						members.push(pm);
					}
				});
			}
		}

		return members;
	},

	async setEntityContext({ commit }, id: number | string | null) {
		if (typeof id === "string") {
			id = parseInt(id, 10);
		}

		if (typeof id === "number" && !isNaN(id)) {
			commit("SET_ENTITY_CONTEXT", id);
			Cookie.set(ENTITY_ID_COOKIE, `${id}`);

			return;
		}

		commit("SET_ENTITY_CONTEXT", null);
		Cookie.remove(ENTITY_ID_COOKIE);
	},
};
