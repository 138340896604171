






























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import clone from "lodash/clone";

import { EntityDto } from "@scrinz/dtos";

@Component
export default class UsersAddDialog extends Vue {
	@Prop({ required: true, type: [EntityDto, Object] })
	entity!: EntityDto;

	@Prop({ default: false })
	value!: boolean;

	@Prop({ type: [Object] })
	data!: any;

	protected _data: any | null = null;

	get isOpen() {
		return this.value;
	}

	set isOpen(value: boolean) {
		this.$emit("input", value);
	}

	@Watch("data", { immediate: true })
	onDataChange() {
		this._data = clone(this.data);
	}

	async submit(data: any) {
		await this.$store.dispatch("addMembersToEntity", {
			entity: this.entity,
			members: [data],
		});

		this.isOpen = false;
		this.onDataChange();
	}
}
