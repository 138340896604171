import { render, staticRenderFns } from "./EditNoteDialog.vue?vue&type=template&id=b99364f4&scoped=true&"
import script from "./EditNoteDialog.vue?vue&type=script&lang=ts&"
export * from "./EditNoteDialog.vue?vue&type=script&lang=ts&"
import style0 from "./EditNoteDialog.vue?vue&type=style&index=0&id=b99364f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b99364f4",
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fscrinz%2Fapplications%2Fapp-ui%2Fsrc%2Fcomponents%2FNoteBoard%2FEditNoteDialog.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports