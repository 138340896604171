












































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import clone from "lodash/clone";
import {
	get as getCookie,
	remove as removeCookie,
	set as setCookie,
} from "es-cookie";

import { EntityDto } from "@scrinz/dtos";
// import Calendar from "@/components/Calendar";
import Calendar from "@/components/Calendar";
import EditTemplateDialog from "./EditTemplateDialog.vue";
import SwimlaneView from "./SwimlaneView.vue";
// import TemplatesEditorSwimlane from "./Swimlane.vue";

import { Getter } from "vuex-class";

const DEFAULT_TEMPLATE = {
	asset: null,
	start: new Date(),
	end: new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0, 0)).valueOf() + (1000 * 60 * 60 * 24 * 14)), // tslint:disable-line
	duration: 30,

};

@Component({
	components: {
		Calendar,
		EditTemplateDialog,
		SwimlaneView,
		// TemplatesEditorSwimlane,
	},
})
export default class TemplatesEditor extends Vue {
	@Getter contentsFor!: (entity: EntityDto, slot: string) => any[];

	@Prop({ required: true, type: [EntityDto, Object] })
	entity!: EntityDto;

	@Prop({ default: "template" })
	slotId!: string;

	@Prop()
	slotWidth!: number;

	@Prop()
	slotHeight!: number;

	// view: "swimlanes" | "calendar" = "swimlanes";
	view: string = getCookie("templates-view") || "swimlanes";

	now = new Date();
	events: any[] = [];

	loading = true;
	showEditDialog: boolean = false;
	editTemplate: any = clone(DEFAULT_TEMPLATE);

	get upcomingEvents() {
		return this.events.filter(event => {
			if (typeof event.isTemplate === "boolean" && event.isTemplate) {
				return false;
			}
			return event.end.valueOf() >= this.now.valueOf();
		});
	}

	get rowHeight() {
		return 30; // tslint:disable-line
	}

	@Watch("entity", { immediate: true })
	@Watch("slotId")
	async fetch() {
		this.loading = true;
		await this.$store.dispatch("fetchContents", { entity: this.entity, slot: this.slotId });
		this.loading = false;
		this.update();
	}

	@Watch("view")
	updateViewCookie() {
		removeCookie("templates-view");
		setCookie("templates-view", this.view);
	}

	edit(template: any = {}) {
		// (this.$refs["edit-template-dialog"] as any).showDialog(template || clone(DEFAULT_TEMPLATE));

		this.editTemplate = {
			...clone(DEFAULT_TEMPLATE),
			...template,
		};

		this.showEditDialog = true;
	}

	update() {
		this.events = this.contentsFor(this.entity, this.slotId);
	}

	async submit(data: any) {
		return this.$store.dispatch("updateEntity", data);
	}
}
