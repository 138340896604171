import { GetterTree } from "vuex";

import { isOrganization } from "@scrinz/helpers";
import { RootState } from "@/store/types";
import {
	EntitiesState,
	GetChildrenOfEntityFunction,
	GetEntityChainIdsFunction,
	GetEntityFunction,
	GetOrganizationOfEntityFunction,
} from "./types";

// tslint:disable:no-shadowed-variable
export const getters: GetterTree<EntitiesState, RootState> = {
	getEntity(state): GetEntityFunction {
		return id => {
			return state.entities.find(entity => entity.id === parseInt((id as any as string), 10));
		};
	},

	getEntityChainIds(_state, getters): GetEntityChainIdsFunction {
		return entity => {
			const ids = [];

			while (entity) {
				ids.push(entity.id);
				entity = getters.getEntity(entity.parentId);
			}

			return ids;
		};
	},

	getChildrenOfEntity(state): GetChildrenOfEntityFunction {
		return entity => {
			return state.entities.filter(e => e.parentId === entity.id);
		};
	},

	getOrganizationOfEntity(_state, getters): GetOrganizationOfEntityFunction {
		return entity => {
			// Can't do much without an entity.
			if (!entity) return;

			// If entity is organization, return it self.
			if (isOrganization(entity)) return entity;

			// Can't continue searching if entity don't have a parent.
			if (!entity.parentId) return;

			// Return a recursive search, with parent as next entity.
			return getters.getOrganizationOfEntity(getters.getEntity(entity.parentId));
		};
	},

	entities(state) {
		return state.entities;
	},

	entitiesInContext(_state, getters) {
		if (!getters.entityContext) return [];

		return [getters.entityContext].concat(getters.getChildrenOfEntity(getters.entityContext));
	},

	organizations(state) {
		return state.entities.filter(e => e.id === 1 || isOrganization(e));
	},

	organizationsInContext(_state, getters) {
		return getters.entitiesInContext.filter(isOrganization);
	},

	entityContext(_state, getters) {
		return getters.getEntity(getters.entityContextId);
	},

	entityContextId(state) {
		return state.entityContext;
	},
};
