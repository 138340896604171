import { render, staticRenderFns } from "./Cropper.vue?vue&type=template&id=47ecd8cc&scoped=true&"
import script from "./Cropper.vue?vue&type=script&lang=ts&"
export * from "./Cropper.vue?vue&type=script&lang=ts&"
import style0 from "./Cropper.vue?vue&type=style&index=0&id=47ecd8cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ecd8cc",
  null
  
)

export default component.exports