











import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import TemplatesEditor from "@/components/TemplatesEditor";

const AD_SIZES: any = {
	small: {
		id: "adsSmall",
		width: 480,
		height: 175,
	},
	large: {
		id: "adsLarge",
		width: 590,
		height: 630,
	},
};

@Component<ClientEntityDetailsAds>({
	title: vm => ["Ads", vm.entity.name],
	components: { TemplatesEditor },
})
export default class ClientEntityDetailsAds extends Vue {
	@Prop({ required: true })
	id!: string;

	@Prop({ required: true })
	size!: string;

	get entity() {
		return this.$store.getters.getEntity(this.id);
	}

	get adSize() {
		if (!(this.size in AD_SIZES)) {
			throw new Error("Unknown ad size");
		}

		return AD_SIZES[this.size];
	}
}
