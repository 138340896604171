




































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Swimlane from "./Swimlane.vue";

@Component({
	components: { Swimlane }
})
export default class SwimlaneView extends Vue {
	@Prop({ required: true })
	entity!: any;

	@Prop({ default: [] })
	events!: any[];

	now = new Date();
	dates: Date[] = [];
	swimlanes: any[] = [];

	get sorted() {
		return this.events.sort((a, b) => {
			const av = a.start.valueOf();
			const bv = b.start.valueOf();

			return av > bv ? 1 : av < bv ? -1 : 0;
		});
	}

	isFirstOfMonth(date: Date, index: number) {
		return index === 0 || date.getDate() === 1;
	}

	@Watch("events", { deep: true, immediate: true })
	onEventsChange() {
		this.generateDatesArray();
		this.generateSwimlanesArray();
	}

	// Generates an array of all dates in ranges found in events property.
	generateDatesArray() {
		this.dates = [];

		if (this.sorted.length <= 0) return;

		// const lastDate = this.events.reduce((prev, current) => prev.end.valueOf() > current.end.valueOf() ? prev : current).end;
		const lastDate = this.sorted[this.sorted.length - 1].end;
		let current = new Date(this.now.valueOf());

		while (current.valueOf() < lastDate.valueOf()) {
			this.dates.push(current);
			current = new Date(current.valueOf() + 1000*60*60*24); // tslint:disable-line
		}
	}

	// Generates array arrays representing swimlanes and events in that swimlane,
	// adding events to the first swimlane without overlapping events.
	generateSwimlanesArray() {
		this.swimlanes = [];

		const swimlanes: any[] = [];
		let previous: any = null;
		let overlap = 0;

		this.sorted.forEach(slot => {
			if (previous && slot.start.valueOf() < previous.end.valueOf()) {
				overlap = overlap + 1;
			} else {
				overlap = 0;
			}

			if (!swimlanes[overlap]) {
				swimlanes[overlap] = [];
			}

			swimlanes[overlap].push(slot);
			previous = slot;
		});

		this.swimlanes = swimlanes;
	}
}
