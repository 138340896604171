





















import { Component, Prop, Watch } from "vue-property-decorator";
import { AsYouType, CountryCode, formatNumber, getCountryCallingCode, isValidNumber, parseNumber } from "libphonenumber-js";
import getenv from "getenv.ts";

import Input from "./Input.vue";

const DEFAULT_COUNTRY_CODE = getenv.string("VUE_APP_DEFAULT_COUNTRY_CODE") as CountryCode;

@Component
export default class SzTelInput extends Input {
	@Prop()
	value!: string;

	input: AsYouType = new AsYouType(DEFAULT_COUNTRY_CODE);
	country: CountryCode = DEFAULT_COUNTRY_CODE;
	tel: string = "";

	get countryLabel() {
		return `+${this.countryCallingCode} (${this.country})`;
	}

	get countryCallingCode() {
		return getCountryCallingCode(this.country);
	}

	get isValid() {
		return isValidNumber(this.tel, this.country);
	}

	@Watch("country")
	onCountryChanged() {
		this.input = new AsYouType(this.country);
	}

	@Watch("tel")
	onNumberChanged() {
		if (this.tel === "") {
			this.$emit("input", null);

			return;
		}

		const input = this.input;

		input.reset();
		input.input(this.tel);

		if (input.country && input.country !== this.country) {
			this.country = input.country;
		}

		if (this.isValid) {
			this.tel = formatNumber(this.tel, this.country, "National");
			this.$emit("input", `00${this.countryCallingCode}${input.getNationalNumber()}`);
		} else {
			this.$emit("input", `00${this.countryCallingCode}${this.tel.replace(/\s/g, "")}`);
		}
	}

	@Watch("value", { immediate: true })
	onValueChanged() {
		if (this.value === "") this.tel = "";
		if (!this.value) return;

		let tel = this.value;

		if (tel.substr(0, 2) === "00") {
			tel = `+${tel.slice(2)}`;
		}

		const parsed = parseNumber(tel);

		if ("country" in parsed) this.country = parsed.country;
		if ("phone" in parsed) this.tel = formatNumber(parsed.phone, this.country, "National");
	}
}
