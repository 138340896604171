import { MutationTree } from "vuex";

import { EntitiesState } from "./types";
import { EntityDto } from "@scrinz/dtos";

// tslint:disable:naming-convention
export const mutations: MutationTree<EntitiesState> = {
	RESET_STATE(state) {
		state.entities = [];
		state.entityContext = null;
	},

	UPDATE_ENTITY(state, entity: EntityDto) {
		if (!(entity instanceof EntityDto)) entity = new EntityDto(entity);

		if ((entity as any).deleted) return mutations.REMOVE_ENTITY(state, entity);

		const current = state.entities.find(e => e.id === entity.id);

		if (current) {
			Object.assign(current, entity);
		} else {
			state.entities.push(entity);
		}
	},

	UPDATE_ENTITIES(state, entities: EntityDto[]) {
		entities.forEach(entity => {
			mutations.UPDATE_ENTITY(state, entity);
		});
	},

	REMOVE_ENTITY(state, entity: EntityDto) {
		const idx = state.entities.findIndex(e => e.id === entity.id);

		if (idx === -1) return;

		state.entities.splice(idx, 1);
	},

	SET_ENTITY_CONTEXT(state, id: number | null) {
		state.entityContext = id;
	},
};
