



















































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import getenv from "getenv.ts";

import { loginRedirect } from "@/helpers";

import LoginForm from "@/routes/Global/LoginForm.vue";

@Component<Authentication>({
	components: { LoginForm },
})
export default class Authentication extends Vue {
	supportEmail: string = getenv.string("VUE_APP_CONTENT_SUPPORT_EMAIL");

	resetPasswordRequestSending = false;
	resetPasswordRequestSent = false;
	resetPasswordEmail: string = "";

	@Prop()
	resetPasswordConfirmToken!: string;
	resetPasswordConfirmError = false;
	resetPasswordConfirmSending = false;
	resetPasswordConfirmSent = false;
	resetPasswordConfirmNewPassword: string = "";

	get isLogout() {
		return this.$route.name === "auth--logout";
	}

	get isLogin() {
		return this.$route.name === "auth--login";
	}

	get isResetPassword() {
		return this.$route.name === "auth--reset-password";
	}

	get isResetPasswordConfirm() {
		return this.$route.name === "auth--reset-password-confirm";
	}

	get hasResetPasswordConfirmToken() {
		return !!this.resetPasswordConfirmToken;
	}

	async mounted() {
		// If route is logout, set token to false.
		if (this.isLogout) {
			await this.$store.dispatch("logout");
		}

		// Do inital redirect check.
		await loginRedirect(this);
	}

	async resetPassword() {
		if (this.resetPasswordRequestSending) return;
		this.resetPasswordRequestSending = true;
		await this.$store.dispatch("resetPassword", { email: this.resetPasswordEmail });
		this.resetPasswordRequestSent = true;
	}

	async resetPasswordConfirm() {
		if (this.resetPasswordConfirmSending) return;
		this.resetPasswordConfirmSending = true;

		try {
			await this.$store.dispatch("resetPasswordConfirm", {
				token: this.resetPasswordConfirmToken,
				newPassword: this.resetPasswordConfirmNewPassword,
			});
		} catch (err) {
			this.resetPasswordConfirmError = true;
		}

		this.resetPasswordConfirmSent = true;
	}
}
