import { PluginObject } from "vue";

import {
	SzAutosizeDirective,
	SzClickSelectDirective,
} from "./directives";

import { SzFormatPhoneNumberFilter } from "./filters";

import {
	SzCardForm,
	SzClipboardButton,
	SzColorSwatches,
	SzContentCard,
	SzDatePickerInput,
	SzDialog,
	SzDialogCardForm,
	SzEmptyState,
	SzField,
	SzInput,
	SzLoadingState,
	SzOption,
	SzOptionGroup,
	SzSelect,
	SzTelInput,
	SzTextEditor,
} from "./components";

export {
	// Directives
	SzAutosizeDirective,
	SzClickSelectDirective,

	// Filters
	SzFormatPhoneNumberFilter,

	// Components
	SzCardForm,
	SzClipboardButton,
	SzColorSwatches,
	SzContentCard,
	SzDatePickerInput,
	SzDialog,
	SzDialogCardForm,
	SzEmptyState,
	SzField,
	SzInput,
	SzLoadingState,
	SzOption,
	SzOptionGroup,
	SzSelect,
	SzTelInput,
	SzTextEditor,
};

export const VueScrinz: PluginObject<any> = {
	install: V => {
		// Directives
		SzAutosizeDirective.install(V);
		SzClickSelectDirective.install(V);

		// Filters
		SzFormatPhoneNumberFilter.install(V);

		// Components
		V.component("sz-card-form", SzCardForm);
		V.component("sz-clipboard-button", SzClipboardButton);
		V.component("sz-color-swatches", SzColorSwatches);
		V.component("sz-content-card", SzContentCard);
		V.component("sz-date-picker-input", SzDatePickerInput);
		V.component("sz-dialog", SzDialog);
		V.component("sz-dialog-card-form", SzDialogCardForm);
		V.component("sz-empty-state", SzEmptyState);
		V.component("sz-field", SzField);
		V.component("sz-input", SzInput);
		V.component("sz-loading-state", SzLoadingState);
		V.component("sz-option", SzOption);
		V.component("sz-option-group", SzOptionGroup);
		V.component("sz-select", SzSelect);
		V.component("sz-tel-input", SzTelInput);
		V.component("sz-text-editor", SzTextEditor);
	},
};

export default VueScrinz;
