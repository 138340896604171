


















































































































import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityDto } from "@scrinz/dtos";

@Component
export default class UsersList extends Vue {
	@Prop({ required: true })
	entity!: EntityDto;

	maxSmsNotifications = 2;

	get headers() {
		return [
			{ text: this.$t("User"), value: "user", align: "left", sortable: false },
			{ text: this.$t("Role"), value: "role", align: "center", sortable: false },
			{ text: this.$t("Auto approve SMS"), value: "auto-approve", align: "center", sortable: false },
			{ text: this.$t("SMS Notifications"), value: "sms-notification", align: "center", sortable: false },
			{ value: "actions", sortable: false },
		];
	}

	get users(): any[] {
		return this.$store.getters.usersFor(this.entity);
	}

	get sorted() {
		return this.users;
	}

	get permissions() {
		const users = [];

		for (const user of this.users) {
			const entity = this.inheritedFrom(user);

			users[user.id] = user.permissions.find(
				(p: any) => p.entityId === entity.id,
			);
		}

		return users;
	}

	get smsNotificationsUsed() {
		let used = 0;

		for (const perm of this.permissions) {
			if (!perm) continue;
			if (perm.smsNotifications) used = used + 1;
		}

		return used;
	}

	inheritedFrom(user: any) {
		return this.isDirectMember(user)
			? this.entity
			: this.$store.getters.getEntity(user.permissions[0].entityId);
	}

	isCurrentUser(user: any) {
		return this.$store.getters.user.id === user.id;
	}

	isDirectMember(user: any) {
		return !!user.permissions.find((p: any) => p.entityId === this.entity.id);
	}

	getRole(user: any) {
		return this.isDirectMember(user)
			? user.permissions.find((p: any) => p.entityId === this.entity.id).role
			: user.permissions[0].role;
	}

	async setAutoApprove(event: Event, user: any) {
		await this.updateMemberPermissions(user, {
			autoApprove: !!event,
		});
	}

	disableSmsNotificationsCheckbox(user: any) {
		const used = this.smsNotificationsUsed;
		const max = this.maxSmsNotifications;
		const userEnabled = this.permissions[user.id].smsNotifications;

		return used >= max && !userEnabled;
	}

	async setSmsNotifications(state: boolean, user: any) {
		await this.updateMemberPermissions(user, {
			smsNotifications: state,
		});
	}

	async updateMemberPermissions(member: any, permissions: any) {
		await this.$store.dispatch("updateMemberPermissions", {
			member,
			permissions,
			entity: this.inheritedFrom(member),
		});
	}

	async remove(member: any) {
		const entity = this.inheritedFrom(member);
		const confirmed = await this.$confirm(
			this.$t(`confirmDelete.text`, {
				member: member.email,
				entity: entity.name,
			}),
			{
				color: "error",
				title: this.$t(`confirmDelete.title`),
			},
		);

		if (!confirmed) return;

		await this.$store.dispatch("removeMemberFromEntity", {
			entity,
			member,
		});
	}
}
