




































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import getenv from "getenv.ts";

import { encodeHashid } from "@scrinz/helpers";
import { DISPLAY_URL } from "@/constants";
import { EntityList } from "@/components/Entity";

@Component<ClientEntityDetailsGeneral>({
	title: vm => ["General Information", vm.entity.name],
	components: { EntityList },
})
export default class ClientEntityDetailsGeneral extends Vue {
	@Prop({ required: true })
	id!: string;

	get entity() {
		return this.$store.getters.getEntity(parseInt(this.id, 10));
	}

	get hashid() {
		return encodeHashid("display", this.entity.id);
	}

	get previewLink() {
		return `${DISPLAY_URL}/preview/${this.hashid}`; // tslint:disable-line
	}

	get smsNumber() {
		return getenv.string("VUE_APP_SMS_NUMBER");
	}

	async regenerateApiKey() {
		if (
			!this.entity.apiKey ||
			confirm(this.$t("This will invalidate any display registered with the existing API key. Are you sure?") as string)
		) {
			await this.$store.dispatch("regenerateEntityApiKey", this.entity);
		}
	}
}
