














import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import clone from "lodash/clone";

import { EntityDto } from "@scrinz/dtos";

import UsersList from "./List.vue";
import UsersAddDialog from "./AddDialog.vue";

@Component({
	components: {
		UsersAddDialog,
		UsersList,
	},
})
export default class UsersCard extends Vue {
	@Prop({ required: true, type: [EntityDto, Object] })
	entity!: EntityDto;

	showAddDialog = false;
	addData: any = this.EMPTY;

	// tslint:disable-next-line
	get EMPTY(): any {
		return clone({});
	}

	get isEmpty() {
		return this.members.length === 0;
	}

	get members() {
		return this.$store.getters.usersFor(this.entity);
	}
}
