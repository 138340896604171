import Vue from "vue";
import VueRouter, { RouterOptions } from "vue-router";

// Use VueRouter.
Vue.use(VueRouter);

import IsAdminGuard from "@/guards/IsAdmin.guard";
import IsLoggedInGuard from "@/guards/IsLoggedIn.guard";
import IsInstalledGuard from "@/guards/IsInstalled.guard";

import Authentication from "@/routes/Authentication.vue";
import GlobalError404 from "@/routes/GlobalError404.vue";
import Installation from "@/routes/Installation.vue";
import DebugRoute from "@/routes/DebugRoute.vue";

import Admin from "@/routes/Admin.vue";
import AdminError404 from "@/routes/Admin/Error404.vue";
import AdminMaster from "@/routes/Admin/Master.vue";
import AdminDisplays from "@/routes/Admin/Displays.vue";
import AdminUserList from "@/routes/Admin/UserList.vue";
import AdminUserDetails from "@/routes/Admin/UserDetails.vue";

import Client from "@/routes/Client.vue";
import ClientError404 from "@/routes/Client/Error404.vue";
import ClientSettings from "@/routes/Client/Settings.vue";
import ClientSettingsProfile from "@/routes/Client/Settings/Profile.vue";
import ClientSettingsPassword from "@/routes/Client/Settings/Password.vue";

import ClientEntities from "@/routes/Client/Entities.vue";
import ClientEntityDetails from "@/routes/Client/EntityDetails.vue";
import ClientEntityDetailsGeneral from "@/routes/Client/EntityDetails/General.vue";
import ClientEntityDetailsSettings from "@/routes/Client/EntityDetails/Settings.vue";
import ClientEntityDetailsMembers from "@/routes/Client/EntityDetails/Members.vue";
import ClientEntityDetailsNotes from "@/routes/Client/EntityDetails/Notes.vue";
import ClientEntityDetailsTemplates from "@/routes/Client/EntityDetails/Templates.vue";
import ClientEntityDetailsAds from "@/routes/Client/EntityDetails/Ads.vue";

export const routerOptions: RouterOptions = {
	mode: "history",
	routes: [
		/**
		 * Fresh instance installation
		 * ===========================
		 */
		{
			name: "install",
			path: "/install",
			component: Installation,
		},

		/**
		 * Authentication routes
		 * =====================
		 */
		{
			path: "/auth",
			component: Authentication,
			props: true,
			meta: {
				requiresInstall: true,
			},
			children: [
				{
					name: "auth--login",
					path: "login",
					component: Authentication,
					meta: {
						title: "Login",
					},
				},
				{
					name: "auth--reset-password",
					path: "reset-password",
					component: Authentication,
					meta: {
						title: "Reset password",
					},
				},
				{
					name: "auth--reset-password-confirm",
					path: "reset-password/:resetPasswordConfirmToken",
					component: Authentication,
					meta: {
						title: "Confirm resetting of password",
					},
				},
				{
					name: "auth--logout",
					path: "logout",
					component: Authentication,
					meta: {
						title: "Logged out",
					},
				},
			],
		},

		/**
		 * Superadmin section
		 * ==================
		 */
		{
			path: "/admin",
			component: Admin,
			beforeEnter: IsAdminGuard,
			meta: {
				requiresAuth: true,
				requiresInstall: true,
				appendTitle: "Superadmin",
			},
			children: [
				{
					name: "admin",
					path: "",
					redirect: "displays",
				},
				{
					name: "admin--displays",
					path: "displays",
					component: AdminDisplays,
				},
				{
					name: "admin--master",
					path: "master/:slotId",
					component: AdminMaster,
					props: true,
				},
				{
					name: "admin--users-list",
					path: "users",
					component: AdminUserList,
				},
				{
					name: "admin--user-details",
					path: "users/:userId",
					component: AdminUserDetails,
					props: (route) => ({
						userId:
							typeof route.params.userId === "number"
							? route.params.userId
							: parseInt(route.params.userId, 10)
					}),
				},


				// Catch all unmatched rules in admin context.
				{
					name: "admin--error-404",
					path: "*",
					component: AdminError404,
				},
			],
		},

		/**
		 * The main application
		 * ====================
		 */
		{
			path: "/",
			component: Client,
			meta: {
				requiresAuth: true,
				requiresInstall: true,
			},
			children: [
				{
					name: "client",
					path: "",
					redirect: "entities",
				},

				{
					path: "settings",
					component: ClientSettings,
					children: [
						{
							name: "client--settings",
							path: "",
							redirect: "profile",
						},
						{
							name: "client--settings--profile",
							path: "profile",
							component: ClientSettingsProfile,
						},
						{
							name: "client--settings--password",
							path: "password",
							component: ClientSettingsPassword,
						},
					],
				},

				{
					name: "client--entities",
					path: "entities",
					component: ClientEntities,
				},

				{
					path: "entities/:id",
					component: ClientEntityDetails,
					props: true,
					children: [
						{
							name: "client--entity",
							path: "",
							redirect: "general",
						},
						{
							name: "client--entity--general",
							path: "general",
							component: ClientEntityDetailsGeneral,
							props: true,
						},
						{
							name: "client--entity--settings",
							path: "settings",
							component: ClientEntityDetailsSettings,
							props: true,
						},
						{
							name: "client--entity--members",
							path: "members",
							component: ClientEntityDetailsMembers,
							props: true,
						},
						{
							name: "client--entity--notes",
							path: "notes",
							component: ClientEntityDetailsNotes,
							props: true,
						},
						{
							name: "client--entity--templates",
							path: "templates",
							component: ClientEntityDetailsTemplates,
							props: true,
						},
						{
							name: "client--entity--ads",
							path: "ads/:size",
							component: ClientEntityDetailsAds,
							props: true,
						},
					],
				},

				// Catch all unmatched rules in application context.
				{
					name: "client--error-404",
					path: "*",
					component: ClientError404,
				},
			],
		},

		// Debugging paths.
		{
			path: "/debug",
			component: DebugRoute,
			props: true,
			meta: {
				requiresInstall: true,
			},
			children: [
				{
					name: "debug--prop",
					path: "prop/:someProp",
					component: DebugRoute,
					meta: {
						title: "Debug Route Prop",
					},
				},
			],
		},

		// Catch all unmatched rules in global context.
		{
			name: "global--error-404",
			path: "*",
			component: GlobalError404,
		},
	],
};

const router = new VueRouter(routerOptions);

// Apply guards.
router.beforeEach(IsInstalledGuard);
router.beforeEach(IsLoggedInGuard);

export default router;
