

























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import clone from "lodash/clone";

import { EntityDto } from "@scrinz/dtos";
import { TransitStop } from "@/store";

import SearchInput from "./SearchInput.vue";

@Component({
	components: { SearchInput },
})
export default class TransitTimesStopSelectorAddDialog extends Vue {
	@Prop({ required: true, type: [EntityDto, Object] })
	entity!: EntityDto;

	@Prop({ default: false })
	value!: boolean;

	@Prop({ type: [Object] })
	stop!: TransitStop | null;

	item: TransitStop | null = null;

	get isOpen() {
		return this.value;
	}

	set isOpen(value: boolean) {
		this.$emit("input", value);
	}

	@Watch("stop", { immediate: true })
	onStopChange() {
		this.item = clone(this.stop);
	}

	async onStopSelected(stop: TransitStop) {
		stop.entityId = this.entity.id;

		await this.$store.dispatch("addTransitStop", stop);

		this.isOpen = false;
	}
}
