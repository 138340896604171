import { Ability, AbilityBuilder } from "@casl/ability";
import Vue from "vue";

import { UserDto } from "@scrinz/dtos";
import store from "@/store";

Vue.use(abilitiesPlugin);

function abilitiesPlugin(vue: typeof Vue) {
	vue.mixin({
		methods: {
			$can(...args) {
				return store.getters.ability.can(...args);
			},

			$cannot(...args) {
				return store.getters.ability.cannot(...args);
			},
		},
	});
}

export function defineAbilitiesFor(user: UserDto) {
	const { can, /* cannot, */ rules } = AbilityBuilder.extract();

	if (user.admin) {
		can("admin", "all");
		can("create", ["Entity"]);
	}

	can("update", ["Entity"]);
	// can("delete", ["Entity"], {  });
	can("read", "all");

	return new Ability(rules);
}

declare module "vue/types/vue" {
	interface Vue {
		$can(...args: any[]): boolean;
		$cannot(...args: any[]): boolean;
	}
}
