

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { EntityDto } from "@scrinz/dtos";
import TemplatesEditorSlot from "./Slot.vue";

// tslint:disable:no-magic-numbers
function toSizePx(days: number) {
	return `${days * 30}px`;
}

function diffInDays(first: Date, second: Date) {
	const diff = second.valueOf() - first.valueOf();

	return Math.ceil(diff / 1000 / 60 / 60 / 24);
}
// tslint:enable:no-magic-numbers

@Component({
	components: { TemplatesEditorSlot },
})
export default class TemplatesEditorSwimlane extends Vue {
	@Prop({ required: true, type: [EntityDto, Object] })
	entity!: EntityDto;

	@Prop({ required: true, type: [Date] })
	now!: Date;

	@Prop({ required: true })
	slots!: any[];

	@Prop({ default: false })
	locked!: boolean;

	getClassForSlot(slot: any) {
		return {
			"starts-before-now": this.doesSlotStartBeforeNow(slot),
		};
	}

	doesSlotStartBeforeNow({ start }: any) {
		return start.valueOf() < this.now.valueOf();
	}

	getStyleForSlot(slot: any) {
		return {
			top: this.getPositionOfSlot(slot),
			height: this.getHeightOfSlot(slot),
		};
	}

	getPositionOfSlot({ start }: any) {
		let pos = diffInDays(this.now, start);

		if (pos < 0) pos = 0;

		return toSizePx(pos);
	}

	getHeightOfSlot({ start, end }: any) {
		if (start.valueOf() < this.now.valueOf()) start = this.now;

		return toSizePx(diffInDays(start, end));
	}
}
