import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// tslint:disable:object-literal-key-quotes max-line-length quotemark
const messages: VueI18n.LocaleMessages = {
	en: {
		navigation: {
			dashboard: "Dashboard",
			entities: "My displays",
		},

		entities: {
			title: "My displays",
		},

		entityDetails: {
			navigation: {
				"General": "General",
				"Settings": "Settings",
				"Members": "Members",
				"Children": "Children",
				"Notes": "Notes",
				"Templates": "Templates",
				"Services": "Services",
			},
		},

		// Generic
		"Locked": "Locked",
		"Edit": "Edit",
		"Date": "Date",
		"Start date": "Start date",
		"End date": "End date",
		"Minimum duration": "Minimum duration",
		"Minimum duration (in seconds)": "Minimum duration (in seconds)",
		"seconds": "seconds",
		"Role": "Role",
		"User": "User",
		"Icon": "Icon",
		"Settings": "Settings",
		"Go to admin": "Go to admin",
		"Log out": "Log out",
		"Profile": "Profile",
		"Password": "Password",

		"Email": "Email",
		"Name": "Name",
		"Address": "Address",
		"Street": "Street",
		"Zip": "Zip",
		"City": "City",
		"Phone number": "Phone number",
		"Description": "Description",
		"Expiration Date": "Expiration Date",
		"Display ID": "Display ID",
		"API key": "API key",
		"Code word": "Code word",
		"SMS code word": "SMS code word",
		"SMS phone number": "SMS phone number",
		"Orientation": "Orientation",
		"Horizontal": "Horizontal",
		"Vertical": "Vertical",

		"Type": "Type",
		"Organization": "Organization",
		"Group": "Group",
		"Display": "Display",

		"Select": "Select",
		"Submit": "Submit",
		"Cancel": "Cancel",
		"Create": "Create",
		"Create and publish": "Create and publish",
		"Save as draft": "Save as draft",
		"Delete": "Delete",
		"Reset": "Reset",
		"Update": "Update",
		"Invite": "Invite",
		"Approved": "Approved",
		"Not approved": "Not approved",
		"Approval status": "Approval status",
		"Pending approval": "Pending approval",
		"Pinned to top": "Pinned to top",
		"Publish": "Publish",
		"Published": "Published",
		"Not published": "Not published",

		"Inherited from": "Inherited from",

		// Authentication
		"Login": "Login",
		"Forgotten password?": "Forgotten password?",
		"Reset password": "Reset password",
		"Confirm resetting of password": "Confirm resetting of password",
		"Back to login?": "Back to login?",
		"Logged out": "Logged out",
		"Log back in?": "Log back in?",
		"Email and password combination not known. Please try again.": "Email and password combination not known. Please try again.",

		authentication: {
			troubleLoggingIn: "Are you having trouble logging in, or other issue with the application? Please contact us at <a href='`mailto:{0}`'>{0}</a>.",
			emailPasswordNotKnown: "Email and password combination not known. Please try again.",
			successfullyLoggedOut: "You have been successfully logged out.",
		},

		// Client / Settings / Profile
		"Profile settings": "Profile settings",
		"Your profile settings have been successfully updated!": "Your profile settings have been successfully updated!",
		"Failed to update profile settings.": "Failed to update profile settings.",

		// Client / Settings / Password
		"Change password": "Change password",
		"Current password": "Current password",
		"New password": "New password",
		"Confirm new password": "Confirm new password",
		"Your password have been successfully updated!": "Your password have been successfully updated!",
		"Failed to update your password. Please confirm you entered the correct current password.": "Failed to update your password. Please confirm you entered the correct current password.",
		"Passwords does not match. Please try again.": "Passwords does not match. Please try again.",

		// Client / EntityDetails / General
		"General information": "General information",
		"Display information": "Display information",
		"Preview display": "Preview display",
		"Child entities of {name}": "Child entities of {name}",
		"No code word set for {0}": "No code word set for {0}",
		"No sms number set for {0}": "No sms number set for {0}",
		"No API key set for display": "No API key set for display",
		"Regenerate API key": "Regenerate API key",
		"This will invalidate any display registered with the existing API key. Are you sure?": "This will invalidate any display registered with the existing API key. Are you sure?",

		// Client / EntityDetails / Settings
		"Entity settings": "Entity settings",
		"Display settings": "Display settings",
		"Display information text": "Display information text",
		"SMS settings": "SMS settings",
		"Housekeeping": "Housekeeping",
		"Delete entity": "Delete entity",
		"Delete {name}": "Delete {name}",
		"The entity settings have been successfully updated.": "The entity settings have been successfully updated.",
		"Failed to update entity settings.": "Failed to update entity settings.",
		"The display settings have been sucessfully updated.": "The display settings have been sucessfully updated.",
		"Failed to update display settings.": "Failed to update display settings.",
		"The SMS settings have been successfully updated.": "The SMS settings have been successfully updated.",
		"Failed to update SMS settings.": "Failed to update SMS settings.",

		// Client / EntityDetails / Members
		"Members of {name}": "Members of {name}",
		"Add member": "Add member",
		"Invite member": "Invite member",

		// Client / EntityDetails / Services
		"Service Settings": "Service Settings",
		"The service settings have been successfully updated.": "The service settings have been successfully updated.",
		"Failed to update service settings.": "Failed to update service settings",
		"Public Transit Service": "Public Transit Service",
		"Click to browse the different features.": "Click to browse the different features.",
		"Add Stop Place": "Add Stop Place",
		"Search Stop Place": "Search Stop Place",
		"The search was successful.": "The Search was successful.",
		"The search failed.": "The search failed.",
		"Enter a location.": "Enter a location.",
		"Search for public transit stop places.": "Search for public transit stop places.",
		"Search": "Search",
		"Search Location": "Search Location",
		"Enter a keyword to search for a location.": "Enter a keyword to search for a location.",
		"Category": "Category",
		"No Results Found": "No Results Found",
		"Please try a different search term.": "Please try a different search term.",
		"Filters": "Filters",
		"County": "County",

		// Client / EntityDetails / Banners
		"Banner settings": "Banner settings",
		"Failed to update banner settings": "Failed to update banner settings",
		"The banner settings have been successfully updated": "The banner settings have been successfully updated",
		"Fallback text for banner section": "Fallback text for banner section",

		// Roles
		"Owner": "Owner",
		"Administrator": "Administrator",
		"Moderator": "Moderator",

		// EntityList
		"New entity": "New entity",
		"Create new entity under {0}": "Create new entity under {0}",

		// AssetUploader
		"Page:": "Page:",
		"Scale:": "Scale:",
		"Clear asset": "Clear asset",
		"Drop asset here or click to select upload": "Drop asset here or click to select upload",

		// Component / NoteBoard / Icons
		"Choose icon": "Choose icon",
		"Exclamation": "Exclamation",
		"Gear": "Gear",
		"Speech bubble": "Speech bubble",

		// UserList
		"Auto approve SMS": "Auto approve SMS",
		"SMS Notifications": "SMS Notifications",
		"It's you": "It's you",
		"Remove member from {0}": "Remove member from {0}",
		"The maxium number of {0} SMS notifications have been used": "The maxium number of {0} SMS notifications have been used",
		"Navigate to {0} in order to edit this option": "Navigate to {0} in order to edit this option",

		// Component / TemplatesEditor
		"Templates for {name}": "Templates for {name}",
		"No templates": "No templates",
		"Create a template": "Create a template",
		"New template": "New template",
		"Edit template": "Edit template",
		"Template image": "Template image",
		"No asset uploaded": "No asset uploaded",
		"Here you can add templates that will be shown on the display.": "Here you can add templates that will be shown on the display.",
		"Are you sure you want to delete this template?": "Are you sure you want to delete this template?",
	},

	no: {
		navigation: {
			dashboard: "Dashboard",
			entities: "Mine skjermer",
		},

		entities: {
			title: "Mine skjermer",
		},

		entityDetails: {
			navigation: {
				"General": "Generelt",
				"Settings": "Innstillinger",
				"Members": "Medlemmer",
				"Children": "Children",
				"Notes": "Oppslag",
				"Templates": "Malverk",
				"Services": "Moduler",
			},
		},

		// Titles
		"404: Page Not Found": "404: Side Ikke Funnet",
		"404: Not Found": "404: Ikke Funnet",
		"Change Password": "Bytt Passord",
		"Entities": "Mine Skjermer",
		"Entity Details": "Enhetsdetaljer",
		"Entity Settings": "Enhetsinnstillinger",
		"General Information": "Generell Informasjon",
		"Members": "Medlemmer",
		"Notes": "Oppslag",
		"Profile Settings": "Profilinnstillinger",
		"Settings": "Innstillinger",
		"Templates": "Malverk",

		// Generic
		"Locked": "Låst",
		"Edit": "Rediger",
		"Date": "Dato",
		"Start date": "Starttidspunkt",
		"End date": "Slutttidspunkt",
		"Minimum duration": "Minste varighet",
		"Minimum duration (in seconds)": "Minste varighet (i sekunder)",
		"seconds": "sekunder",
		"Role": "Rolle",
		"User": "Bruker",
		"Icon": "Ikon",
		"Go to admin": "Gå til admin",
		"Log out": "Logg ut",
		"Profile": "Profil",
		"Password": "Passord",

		"Email": "Epost",
		"Name": "Navn",
		"Address": "Adresse",
		"Street": "Gate",
		"Zip": "Postkode",
		"City": "By",
		"Phone number": "Telefonnummer",
		"Description": "Beskrivelse",
		"Expiration Date": "Utløpsdato",
		"Display ID": "Skjerm ID",
		"API key": "API-nøkkel",
		"Code word": "Kodeord",
		"SMS code word": "SMS kodeord",
		"SMS phone number": "SMS nummer",
		"Orientation": "Orientering",
		"Horizontal": "Horisontal",
		"Vertical": "Vertikal",

		"Type": "Type",
		"Organization": "Organisasjon",
		"Group": "Gruppe",
		"Display": "Skjerm",

		"Select": "Velg",
		"Submit": "Send inn",
		"Cancel": "Avbryt",
		"Create": "Opprett",
		"Create and publish": "Opprett og publiser",
		"Save as draft": "Lagre utkast",
		"Delete": "Slett",
		"Reset": "Tilbakestill",
		"Update": "Oppdater",
		"Invite": "Inviter",
		"Approved": "Godkjent",
		"Not approved": "Ikke godkjent",
		"Approval status": "Godkjenningstatus",
		"Pending approval": "Venter godkjenning",
		"Pinned to top": "Festet til toppen",
		"Publish": "Publiser",
		"Published": "Publisert",
		"Not published": "Ikke publisert",

		"Inherited from": "Arvet av",

		// Authentication
		"Login": "Logg inn",
		"Forgotten password?": "Glemt passord?",
		"Reset password": "Tilbakestill passord",
		"Confirm resetting of password": "Bekreft tilbakestilling av passord",
		"Back to login?": "Tilbake til login?",
		"Logged out": "Logget ut",
		"Log back in?": "Logg deg inn igjen?",
		"Email and password combination not known. Please try again.": "Kombinasjon av epost og passord ikke funnet. Vennligst prøv igjen.",

		authentication: {
			troubleLoggingIn: "Har du problemer med å logge deg inn, eller andre problemer med applikasjonen? Vennligst kontakt oss på <a href='mailto:{0}'>{0}</a>.",
			emailPasswordNotKnown: "Epost og passord kombinasjon ikke funnet. Vennligst prøv igjen.",
			successfullyLoggedOut: "Du har blitt logget ut.",
		},

		// Client / Settings / Profile
		"Profile settings": "Profilinnstillinger",
		"Your profile settings have been successfully updated!": "Dine profilinnstillinger har blitt vellykket oppdatert!",
		"Failed to update profile settings.": "Feilet i å oppdatere profilinnstillinger.",

		// Client / Settings / Password
		"Change password": "Endre passord",
		"Current password": "Gjeldende passord",
		"New password": "Nytt passord",
		"Confirm new password": "Gjenta nytt passord",
		"Your password have been successfully updated!": "Ditt passord har blitt endret!",
		"Failed to update your password. Please confirm you entered the correct current password.": "Feilet i å endre passord. Vennligst bekreft at du har tastet inn korrekt passord.",
		"Passwords does not match. Please try again.": "Du har tastet inn ulike passord. Vennligst prøv igjen.",

		// Client / EntityDetails / General
		"General information": "Generell informasjon",
		"Display information": "Skjerm-informasjon",
		"Preview display": "Forhåndsvisning av skjerm",
		"Child entities of {name}": "Underenheter av {name}",
		"No code word set for {0}": "Ingen kodeord definert for {0}",
		"No sms number set for {0}": "Ingen SMS nummer definert for {0}",
		"No API key set for display": "Ingen API-nøkkel generert for skjerm",
		"Regenerate API key": "Regenerer API-nøkkel",
		"This will invalidate any display registered with the existing API key. Are you sure?": "Dette vil ugyldiggjøre alle skjermer som er registrert med gjeldende API-nøkkel. Er du sikker?",

		// Client / EntityDetails / Settings
		"Entity settings": "Enhetsinnstillinger",
		"Display settings": "Skjerminnstillinger",
		"Display information text": "Informasjonstekst på skjerm",
		"SMS settings": "SMS-innstillinger",
		"Housekeeping": "Opprydding",
		"Delete entity": "Slett enhet",
		"Delete {name}": "Slett {name}",
		"The entity settings have been successfully updated.": "Enhetsinnstillingene har blitt vellykket oppdatert.",
		"Failed to update entity settings.": "Feilet i å oppdatere enhetsinnstillinger.",
		"The display settings have been sucessfully updated.": "Skjerminnstillingene har blitt vellykket oppdatert.",
		"Failed to update display settings.": "Feilet i å oppdatere skjerminnstillinger.",
		"The SMS settings have been successfully updated.": "SMS-innstillingene har blitt vellykket oppdatert.",
		"Failed to update SMS settings.": "Feilet i å oppdatere SMS-innstillinger.",
		"Show SMS code word and sending instructions?": "Vis kodeord og instrukser for sending av SMS?",
		"Background color for note board": "Bakgrunnsfarge for oppslagstavle",

		// Client / EntityDetails / Members
		"Members of {name}": "Medlemmer av {name}",
		"Add member": "Legg til medlem",
		"Invite member": "Inviter medlem",

		// Client / EntityDetails / Services
		"Service Settings": "Innstillinger for moduler",
		"The service settings have been successfully updated.": "Modulinnstillingene har blitt vellykket oppdatert.",
		"Failed to update service settings.": "Feilet i å oppdatere modulinnstillingene.",
		"Public Transit Service": "Modul for kollektivtrafikk",
		"Click to browse the different features.": "Klikk for å se de forskjellige valgene.",
		"Add Stop Place": "Legg til stoppested",
		"Search Stop Place": "Søk på stoppested",
		"The search was successful.": "Søket var vellykket.",
		"The search failed.": "Søket feilet.",
		"Enter a location.": "Søk etter stoppested.",
		"Search for public transit stop places.": "Søk på stoppesteder for kollektivtrafikk.",
		"Search": "Søk",
		"Search Location": "Søk etter stoppested",
		"Enter a keyword to search for a location.": "Skriv noe for å søke etter stoppested.",
		"Category": "Kategori",
		"No Results Found": "Fant ingen resultater",
		"Please try a different search term.": "Forsøk med et annet søkeord.",
		"Filters": "Filter",
		"County": "Fylke",

		// Client / EntityDetails / Banners
		"Banner settings": "Malverksinnstillinger",
		"Failed to update banner settings": "Feilet i å oppdatere Malverksinnstillingene",
		"The banner settings have been successfully updated": "Malverksinnstillingene har blitt vellykket oppdatert.",
		"Fallback text for banner section": "Tekst å vise ved tomt malverk",

		// Roles
		"Owner": "Eier",
		"Administrator": "Administrator",
		"Moderator": "Moderator",

		// EntityList
		"New entity": "Ny enhet",
		"Create new entity under {0}": "Opprett ny enhet under {0}",

		// AssetUploader
		"Page:": "Side:",
		"Scale:": "Skalering:",
		"Clear asset": "Tøm malverk",
		"Drop asset here or click to select upload": "Slipp fil here eller klikke for å velge opplasting",

		// Component / NoteBoard / Icons
		"Choose icon": "Velg ikon",
		"Exclamation": "Utrop",
		"Gear": "Tannhjul",
		"Speech bubble": "Snakkeboble",

		// UserList
		"Auto approve SMS": "Auto-godkjenn SMS",
		"SMS Notifications": "SMS varsler",
		"It's you": "Det er deg",
		"Remove member from {0}": "Fjern medlem fra {0}",
		"The maxium number of {0} SMS notifications have been used": "Det maksimale antallet av {0} SMS varsler er brukt opp",
		"Navigate to {0} in order to edit this option": "Naviger til {0} for å endre dette valget",

		// Component / TemplatesEditor
		"Templates for {name}": "Malverk for {name}",
		"No templates": "Ingen malverk",
		"Create a template": "Legg til et malverk",
		"New template": "Nytt malverk",
		"Edit template": "Rediger malverk",
		"Template image": "Malbilde",
		"No asset uploaded": "Ingen malfil lastet opp",
		"Here you can add templates that will be shown on the display.": "Her kan du legge til malverk som skal vises frem på skjermen.",
		"Are you sure you want to delete this template?": "Er du sikker på at du ønsker å slette dette malverket?",
	},
};

const i18n = new VueI18n({
	messages,
	locale: "no",
	fallbackLocale: "en",
});

export default i18n;
