






































































import { Component, Prop, Vue } from "vue-property-decorator";

import { getTemplateById } from "@/assets/templates";

// function getObjectValue(key: string|null, obj: any): string|null {
// 	if (key === null) return null;

// 	const [first, ...rest] = key.split(".");

// 	if (first in obj) {
// 		if (typeof obj[first] === "object") {
// 			return getObjectValue(rest.join("."), obj[first]);
// 		}

// 		return obj[first];
// 	}

// 	return null;
// }

@Component
export default class TemplatePreview extends Vue {
	@Prop({ default: false, type: [Object, Boolean] })
	event!: any | boolean;

	@Prop({ required: true })
	position?: { top: number; left: number };

	@Prop({ default: true, type: Boolean })
	mobile!: boolean;

	/**
	 * Adjusts the preview position.
	 */
	offset: { top: number; left: number; } = {
		top: -25,
		left: 15,
	};

	get imageUrl() {
		const template = getTemplateById(this.event.template);

		return template ? template.image : "";
	}

	get textClass() {
		const template = getTemplateById(this.event.template);

		return {
			"text--light": !template || template.fontColor === "light" ? true : false,
			"text--dark": template ? template.fontColor === "dark" : false,
		};
	}
}
