import EntityAddDialog from "./AddDialog.vue";
import EntityDeleteDialog from "./DeleteDialog.vue";
import EntityList from "./List.vue";

export {
	EntityAddDialog,
	EntityDeleteDialog,
	EntityList,
};

export default EntityList;
