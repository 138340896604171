export * from "./interfaces";
export * from "./consts";

import SzCalendar from "./Calendar.vue";
import SzCalendarHeader from "./Header.vue";
import SzCalendarTemplatePreview from "./TemplatePreview.vue";

export {
	SzCalendar,
	SzCalendarHeader,
	SzCalendarTemplatePreview
};

export default SzCalendar;
