















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AdminUserDetailsNewPassword extends Vue {
	@Prop()
	userId!: number;

	password: string = "";

	async generateNewPassword() {
		const newPassword = await this.$store.dispatch("generateNewPasswordForUser", this.userId);

		this.password = newPassword;
	}
}
