var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.sorted,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('v-tooltip',{attrs:{"top":""}},[_c('strong',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(item.name))]),_vm._v(" "+_vm._s(item.email)+" ")]):_c('strong',[_vm._v(" "+_vm._s(item.email)+" ")]),(_vm.isCurrentUser(item))?_c('em',[_vm._v(" ("+_vm._s(_vm.$t("It's you"))+") ")]):_vm._e(),(!_vm.isDirectMember(item))?_c('em',[_vm._v(" ("+_vm._s(_vm.$t("Inherited from"))+" "),_c('router-link',{attrs:{"to":("/entities/" + (_vm.inheritedFrom(item).id) + "/members")}},[_vm._v(_vm._s(_vm.inheritedFrom(item).name))]),_vm._v(") ")],1):_vm._e()]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.$scrinz.getRoleName(_vm.getRole(item))))+" ")]}},{key:"item.auto-approve",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":_vm.isDirectMember(item)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"d-inline-block",attrs:{"color":"primary","hide-details":"","true-value":true,"false-value":false,"disabled":!_vm.isDirectMember(item)},on:{"change":function($event){return _vm.setAutoApprove($event, item)}},model:{value:(_vm.permissions[item.id].autoApprove),callback:function ($$v) {_vm.$set(_vm.permissions[item.id], "autoApprove", $$v)},expression:"permissions[item.id].autoApprove"}},on))]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Navigate to {0} in order to edit this option", [_vm.inheritedFrom(item).name]))+" ")])]}},{key:"item.sms-notification",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":_vm.isDirectMember(item) && !_vm.disableSmsNotificationsCheckbox(item)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"d-inline-block",attrs:{"color":"primary","hide-details":"","true-value":true,"false-value":false,"disabled":!_vm.isDirectMember(item) || _vm.disableSmsNotificationsCheckbox(item)},on:{"change":function($event){return _vm.setSmsNotifications($event, item)}},model:{value:(_vm.permissions[item.id].smsNotifications),callback:function ($$v) {_vm.$set(_vm.permissions[item.id], "smsNotifications", $$v)},expression:"permissions[item.id].smsNotifications"}},on))]}}],null,true)},[(_vm.disableSmsNotificationsCheckbox(item))?_c('span',[_vm._v(" "+_vm._s(_vm.$t("The maxium number of {0} SMS notifications have been used", [_vm.maxSmsNotifications]))+" ")]):(!_vm.isDirectMember(item))?_c('span',[_vm._v(" "+_vm._s(_vm.$t("Navigate to {0} in order to edit this option", [_vm.inheritedFrom(item).name]))+" ")]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isDirectMember(item) && !_vm.isCurrentUser(item))?_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.remove(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Remove member from {0}", [_vm.entity.name]))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }