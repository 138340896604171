





































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { AssetDto, EntityDto } from "@scrinz/dtos";
import { API_URL } from "@/constants";

import SlotButton from "./Slot/Button.vue";
import SlotContent from "./Slot/Content.vue";
import SlotPopup from "./Slot/Popup.vue";

import { getTemplateById } from "@/assets/templates";

@Component({
	components: { SlotButton, SlotContent, SlotPopup },
})
export default class TemplatesEditorSlot extends Vue {
	@Prop({ required: true, type: [EntityDto, Object] })
	entity!: EntityDto;

	@Prop({ required: true })
	template!: any;

	@Prop({ default: false })
	isLocked!: boolean;

	@Prop({ default: false })
	startsBeforeView!: boolean;

	imageUrl = "";
	isHover = false;

	get isEditable() {
		return !this.isLocked && !this.isInherited;
	}

	get isInherited() {
		return this.entity.id !== this.template.entityId;
	}

	get classes() {
		return {
			"templates-editor__slot": true,
			"templates-editor__slot--editable": this.isEditable,
			"templates-editor__slot--inherited": this.isInherited,
			"templates-editor__slot--locked": this.isLocked,
			"templates-editor__slot--starts-before-view": this.startsBeforeView,
		};
	}

	get asset(): AssetDto {
		return this.$store.getters.getAsset(this.template.asset);
	}

	get inheritedFrom() {
		return this.$store.getters.getEntity(this.template.entityId);
	}

	onMouseOver(_e: MouseEvent) {
		// console.log(e);
		this.isHover = true;
	}

	onMouseOut(_e: MouseEvent) {
		this.isHover = false;
	}

	// @Watch("asset", { deep: true, immediate: true })
	@Watch("template", { deep: true, immediate: true })
	onTemplateChange() {
		if (!this.template) return;

		if (this.template.type === 1) {
			if (!this.asset) return;

			const version = this.asset.hasCropped ? "cropped" : "original";
			const url = API_URL;
			const path = `/assets/${this.asset.id}/${version}`;
			const cache = `?cache=${new Date().getTime()}`;

			this.imageUrl = `${url}${path}${cache}`;
		}

		else if (this.template.type === 2) {
			const template = getTemplateById(this.template.template);

			if (template) {
				this.imageUrl = template.image;
			}
		}
	}
}
