





















import { Component, Vue } from "vue-property-decorator";

@Component
export default class AdminError404 extends Vue {}
