import { ActionTree } from "vuex";

import http from "@/http";
import { RootState } from "@/store";
import { ServicesState } from "./types";
import { EntityDto } from "@scrinz/dtos";

export const actions: ActionTree<ServicesState, RootState> = {
	async fetchServices({ commit }, { entity }: { entity: EntityDto}) {
		const res = await http.get(`content/${entity.id}/service`);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to load services for entity "${entity.id}".`);
		}

		commit("UPDATE_SERVICES", res.data);
	},

	// async createNote({ commit, getters }, note) {
	// 	if (!note.ownerId) {
	// 		note.ownerId = getters.user.id;
	// 	}

	// 	const res = await http.post(`/content/${note.entityId}/note`, note);

	// 	if (!res || res.status !== 201) {
	// 		throw new Error(`Failed to create note.`);
	// 	}

	// 	// Add newly created flag.
	// 	res.data.newlyCreated = true;

	// 	commit("UPDATE_NOTE", res.data);
	// },

	// async updateNote({ commit }, note) {
	// 	const res = await http.put(`/content/${note.entityId}/note/${note.id}`, note);

	// 	if (!res || res.status !== 200) {
	// 		throw new Error(`Failed to update note with id "${note.id}".`);
	// 	}

	// 	// Add newly updated flag.
	// 	res.data.newlyUpdated = true;

	// 	commit("UPDATE_NOTE", res.data);
	// },

	// async deleteNote({ commit }, note) {
	// 	const res = await http.delete(`/content/${note.entityId}/note/${note.id}`, note);

	// 	if (!res || res.status !== 200) {
	// 		throw new Error(`Failed to delete note with id "${note.id}".`);
	// 	}

	// 	commit("DELETE_NOTE", note);
	// },
};
