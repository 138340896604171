import { OptionsInput as FCOptions } from "@fullcalendar/core";

export const CALENDAR_OPTIONS: FCOptions = {

	editable: true,
	selectable: true,
	weekends: true,
	allDaySlot: false,

	// height: "auto",
	// contentHeight: "auto",
	// aspectRatio: 1.35,

	/**
	 * Colors
	 */
	eventColor: "#33b560",

	/**
	 * Header settings
	 */
	header: false,
	defaultView: "dayGridMonth",

	/**
	 * Header buttons
	 */
	buttonText: {
		today: "Now",
		dayGridDay: "Day",
		dayGridWeek: "Week",
		dayGridMonth: "Month",
		prev: "Back",
		next: "Forward",
	},
	buttonIcons: false,

	/**
	 * Locale
	 */
	locale: "nb",
	firstDay: 1,

	/**
	 * View/Viewset specific settings
	 */
	views: {
		/**
		 * dayGrid settings
		 * Applies to:
		 * dayGridMonth
		 * dayGridWeek
		 * dayGridDay
		 */
		dayGrid: {
			displayEventEnd: true,
			eventTimeFormat: { weekday: "short", day: "numeric", month: "short", hour: "numeric", minute: "2-digit" },
		},

		/**
		 * dayGridMonth settings
		 */
		dayGridMonth: {
			weekNumbers: true,
			weekNumbersWithinDays: true,
			titleFormat: { year: "numeric", month: "short", day: "numeric" },
		},

		/**
		 * dayGridWeek settings
		 */
		dayGridWeek: {
			weekNumbers: true,
			weekNumbersWithinDays: true,
		},

		/**
		 * dayGridDay settinigs
		 */
		dayGridDay: {
			titleFormat: { month: "long", day: "numeric", weekday: "long" }
		},

		/**
		 * timeGrid settings
		 * Applies to:
		 * timeGridWeek
		 * timeGridDay
		 */
		timeGrid: {
			slotLabelFormat: {
				hour: "numeric",
				minute: "2-digit",
			},
		},

		/**
		 * timeGridWeek settings
		 */
		timeGridWeek: {
			weekNumbers: true,
			weekNumbersWithinDays: true,
		},

		/**
		 * timeGridDay settings
		 */
		timeGridDay: {
			titleFormat: { month: "long", day: "numeric", weekday: "long" },
		},
	},
};
