import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { AssetsState } from "./types";

// tslint:disable:no-shadowed-variable
export const getters: GetterTree<AssetsState, RootState> = {
	getAsset(state) {
		return (id: string) => {
			return state.assets.find(asset => asset.id === id);
		};
	},
};
