








































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
	get as getCookie,
} from "es-cookie";
import moment from "moment";

@Component({
	filters: {
		capitalize(str: string) {
			if (!str) return;

			return str.slice(0,1).toUpperCase() + str.slice(1);
		},
		parseDate(date: Date | string) {
			if (typeof(date) === "string") return new Date(date);

			return date;
		},
		weekNum(date: Date) {
			return moment(date).isoWeek();
		}
	},
})
export default class SzCalendarHeader extends Vue {
	@Prop({ default: true })
	navigationButtonsEnabled!: boolean;

	@Prop({ default: true })
	viewButtonsEnabled!: boolean;

	@Prop()
	title!: {};

	@Prop()
	isNow!: boolean;

	activeView: string = getCookie("calendar-view") || "month";

	@Watch("activeView")
	updateView() {
		this.$emit("view", this.activeView);
	}

	updateNavigation(value: any) {
		this.$emit("navigate", value);
	}

	checkIfYearIsEqual(start: Date | string, end: Date | string) {
		if (!start || !end) return;

		if (typeof(start) === "string") start = new Date(start);

		if (typeof(end) === "string") end = new Date(end);

		if (start.getFullYear() > end.getFullYear()) throw new Error(
			"Starting year is higher than ending year. Try re-creating the calendar event, or contact your system administrator.");

		return (start.getFullYear() === end.getFullYear());
	}
}
