import { MutationTree } from "vuex";

import store from "@/store";
import { ContentState } from "./types";

function prepTemplate(template: any) {
	template.start = new Date(template.start);
	template.end = new Date(template.end);

	if (template.asset) {
		store.commit("UPDATE_ASSET", template.asset);
		template.asset = template.asset.id;
	}

	return template;
}

function prepContent(slot: string, content: any) {
	switch (slot) {
		case "adsSmall":
		case "adsLarge":
		case "template":
			return prepTemplate(content);
		default:
			return content;
	}
}

// tslint:disable:naming-convention
export const mutations: MutationTree<ContentState> = {
	RESET_STATE(state) {
		state.slots = {};
	},

	REMOVE_CONTENT(state, { slot, content }: { slot: string; content: any }) {
		if (!state.slots[slot]) return;

		const idx = state.slots[slot].findIndex(t => t.id === content.id);

		if (idx === -1) return;

		state.slots[slot].splice(idx, 1);
	},

	UPDATE_CONTENT(state, { slot, content }: { slot: string; content: any }) {
		if (!state.slots[slot]) state.slots[slot] = [];

		const current = state.slots[slot].find(t => t.id === content.id);

		content = prepContent(slot, content);

		if (current) {
			Object.assign(current, content);
		} else {
			state.slots[slot].push(content);
		}
	},

	UPDATE_CONTENTS(state, { slot, contents }: { slot: string; contents: any[] }) {
		contents.forEach(content => {
			mutations.UPDATE_CONTENT(state, { slot, content });
		});
	},
};
