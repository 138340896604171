import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { AutoApproveNumbersState } from "./types";
import { EntityDto } from "@scrinz/dtos";

// tslint:disable:no-shadowed-variable
export const getters: GetterTree<AutoApproveNumbersState, RootState> = {
	autoApproveNumbersFor(state, getters) {
		return (entity: EntityDto) => {
			const ids = getters.getEntityChainIds(entity) as number[];

			return state.autoApproveNumbers.filter(num => {
				return ids.includes(num.entityId);
			});
		};
	},
};
