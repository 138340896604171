







import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { quillEditor } from "vue-quill-editor";

const DEFAULT_FORMATS = [
	"bold",
	"color",
	"italic",
	"strike",
	"underline",
	"header",
	"list",
	"emoji",
];

const DEFAULT_CONTAINER = [
	[{ header: [1, 2, false] }],
	["bold", "italic", "underline", "strike"],
	[{ color: [] }],
	[{ list: "ordered" }, { list: "bullet" }],
	["emoji"],
];

const DEFAULT_HANDLERS = {
	emoji: function() {},
};

@Component({
	components: { quillEditor },
})
export default class SzTextEditor extends Vue {
	@Prop()
	value!: string;

	@Prop()
	label!: string;

	@Prop({ default: () => DEFAULT_FORMATS })
	formats!: string[];

	@Prop({ default: () => DEFAULT_CONTAINER })
	container!: any[][];

	@Prop({ default: () => DEFAULT_HANDLERS })
	handlers!: { [key: string]: Function };

	@Prop({ default: true })
	emojiToolbar!: boolean;

	@Prop({ default: false })
	emojiTextarea!: boolean;

	@Prop({ default: true })
	emojiShortname!: boolean;

	szValue: string = "";

	get editorOptions() {
		return {
			formats: this.formats,
			modules: {
				toolbar: {
					container: this.container,
					handlers: this.handlers,
				},
				"emoji-toolbar": this.emojiToolbar,
				"emoji-textarea": this.emojiTextarea,
				"emoji-shortname": this.emojiShortname,
			},
		};
	}

	@Watch("value", { immediate: true })
	protected _onValueChange() {
		this.szValue = this.value;
	}

	@Watch("szValue")
	protected _onSzValueChange() {
		this.$emit("input", this.szValue);
	}
}
