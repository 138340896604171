import { render, staticRenderFns } from "./TemplatesEditor.vue?vue&type=template&id=9944ff72&scoped=true&"
import script from "./TemplatesEditor.vue?vue&type=script&lang=ts&"
export * from "./TemplatesEditor.vue?vue&type=script&lang=ts&"
import style0 from "./TemplatesEditor.vue?vue&type=style&index=0&id=9944ff72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9944ff72",
  null
  
)

export default component.exports