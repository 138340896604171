




















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class TemplatesEditorSlotContent extends Vue {
	@Prop({ required: true })
	template!: any;

	@Prop()
	asset!: any;

	@Prop()
	imageUrl!: string;

	@Prop()
	isHover!: boolean;

	@Prop()
	isEditable!: boolean;

	@Prop()
	isLocked!: boolean;

	@Prop()
	isInherited!: boolean;

	get classes() {
		return {
			"templates-editor__slot__content": true,
			"templates-editor__slot__content--hover": this.isHover,
		};
	}

	get inheritedFrom() {
		return this.$store.getters.getEntity(this.template.entityId);
	}
}
