
































import { Component, Prop, Vue } from "vue-property-decorator";

// import http from "@/http";
import store from "@/store";

import LayoutSidenav from "@/layouts/Sidenav.vue";
import EntityBreadcrumbs from "@/components/EntityBreadcrumbs.vue";

@Component<ClientEntityDetails>({
	title: vm => ["Entity Details", vm.entity.name],
	components: { EntityBreadcrumbs, LayoutSidenav },
})
export default class ClientEntityDetails extends Vue {
	@Prop({ required: true })
	id!: string;

	get entity() {
		return store.getters.getEntity(parseInt(this.id, 10));
	}
}
