import { render, staticRenderFns } from "./TemplatePreview.vue?vue&type=template&id=cb0d773e&scoped=true&"
import script from "./TemplatePreview.vue?vue&type=script&lang=ts&"
export * from "./TemplatePreview.vue?vue&type=script&lang=ts&"
import style0 from "./TemplatePreview.vue?vue&type=style&index=0&id=cb0d773e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb0d773e",
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fscrinz%2Fapplications%2Fapp-ui%2Fsrc%2Fcomponents%2FCalendar%2FTemplatePreview.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports