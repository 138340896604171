





















import Vue from "vue";
import { Component } from "vue-property-decorator";

import ContextSwitcher from "@/components/ContextSwitcher.vue";
import StyretavlaLogo from "@/components/StyretavlaLogo.vue";
import UserMenu from "@/components/UserMenu.vue";

@Component({
	components: { ContextSwitcher, StyretavlaLogo, UserMenu },
})
export default class AppToolbar extends Vue {
	toggleDrawer() {
		void this.$store.dispatch("setAppDrawer", !this.$store.getters.appDrawer);
	}
}
