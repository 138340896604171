





















































import Vue from "vue";
import { Component } from "vue-property-decorator";

import { API_URL } from "@/constants";

import LayoutCentered from "@/layouts/Centered.vue";

@Component({
	components: { LayoutCentered },
})
export default class Installation extends Vue {
	adminEmail = "";
	adminPassword = "";
	rootEntity = "";

	get apiUrl() { return API_URL; }

	async submit(e: Event) {
		e.preventDefault();

		await this.$store.dispatch("installApi", {
			adminEmail: this.adminEmail,
			adminPassword: this.adminPassword,
			rootEntity: this.rootEntity,
		});
	}
}
