import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { UsersState } from "./types";
import { EntityDto } from "@scrinz/dtos";

// tslint:disable:no-shadowed-variable
export const getters: GetterTree<UsersState, RootState> = {
	user(state, getters) {
		return state.users.find(user => user.id === getters.userId);
	},

	users(state) {
		return state.users;
	},

	usersFor(state, getters) {
		return (entity: EntityDto) => {
			const ids = getters.getEntityChainIds(entity) as number[];

			return state.users.filter(user => {
				return user.permissions && user.permissions.find((perm: any) => ids.includes(perm.entityId));
			});
		};
	},

	userById(state, _getters) {
		return (userId: number) => {
			return state.users.find(user => {
				return user.id === userId;
			});
		};
	},
};
