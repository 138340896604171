// tslint:disable:no-import-side-effect

// Polyfills.
import "@babel/polyfill";
import "blueimp-canvas-to-blob";

// Plugins.
import registerAnalytics from "@/plugins/analytics";
import "@/plugins/casl";
import i18n from "@/plugins/i18n";
import "@/plugins/moment";
import "@/plugins/portal-vue";
import "@/plugins/scrinz";
import vuetify from "@/plugins/vuetify";
import "@/plugins/vuetify-confirm";
import "@/plugins/vue-gravatar";

// Mixins.
import "@/mixins/UpdateDocumentTitle.mixin";

// Fonts and global css.
import Quill from "quill";
const Emoji = require('quill-emoji');
Quill.register('modules/quill-emoji', Emoji);
import "quill-emoji/dist/quill-emoji.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
// TODO: These are defined in index.html - uncomment and change to enabled offline support.
// import "roboto-fontface/css/roboto/roboto-fontface.css";
// import "material-design-icons-iconfont/dist/material-design-icons.css";

// Initiation.
import Vue from "vue";
import { bootstrapVue } from "@scrinz/vue";

// Register globally to enable recursion.
import NoteBoard from "@/components/NoteBoard";
Vue.component("note-board", NoteBoard);

// Import project globals.
import router from "./router";
import store from "./store";

// Import main component
import Main from "./Main.vue";

import "./styles/main.scss";

// Register vue-analytics once router is ready, to get screenview auto tracking
// to work properly.
router.onReady(() => {
	registerAnalytics({ router });
});

// Bootstrap main component
void bootstrapVue(Vue, "@scrinz/app-ui", Main, {
	router,
	store,
	additionalConstructorProperties: {
		i18n,
		vuetify,
	},
});
